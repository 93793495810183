

import Vue from 'vue'

Vue.mixin({
    methods: {
        alertSuccess() {
            this.$notify({
                title: 'Success',
                message: 'ดำเนินการเสร็จสิ้น',
                position: 'top-left',
                type: 'success'
            });
        },
        alertFailError() {
            this.$message.error(
                {
                    showClose: true,
                    message: 'ระบบขัดข้อง กรุณาลองใหม่',
                    type: 'error'
                }
            );
        },
        alertCatchError() {
            this.$message.error(
                {
                    showClose: true,
                    message: 'เครือข่ายไม่เสถียร กรุณาติดต่อผู้ดูแล',
                    type: 'error'
                }
            );
        },
        alertFileTypeError() {
            this.$message.error(
                {
                    showClose: true,
                    message: 'ระบบไม่รองรับรูปแบบไฟล์',
                    type: 'error'
                }
            );
        },
        alertUploadFileError() {
            this.$message.error(
                {
                    showClose: true,
                    message: 'อัพโหลดไฟล์ล้มเหลว',
                    type: 'error'
                }
            );
        },
        alertProjectDuplicateError() {
            this.$message.error(
                {
                    showClose: true,
                    message: 'ใช้ชื่อโปรเจคซ้ำ',
                    type: 'error'
                }
            );
        },
        alertPermisstionError() {
            this.$message.error(
                {
                    showClose: true,
                    message: 'ไม่มีสิทธิ์ใช้งานในส่วนนี้',
                    type: 'error'
                }
            );
        },
        alertLessWorkSpaceError(){
            this.$message.error(
                {
                    showClose: true,
                    message: 'ต้องมีมากกว่า 1 เวิร์คสเปซ',
                    type: 'error'
                }
            )
        }
    },
  })
  