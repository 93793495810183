<template>
    <el-row type="flex" justify="center" class="full-screen" align="middle">
    	<el-dropdown v-if="user != null " class="fix-top-right mg-t-4  mg-r-10">
	        <span class="el-dropdown-link" style="cursor:pointer">
	         <i class="el-icon-more fa-rotate-90"></i>
	        </span>
	        <el-dropdown-menu slot="dropdown">
	          <el-dropdown-item>
	            <div @click="signout()">
	              ออกจากระบบ
	            </div>
	          </el-dropdown-item>
	        </el-dropdown-menu>
	    </el-dropdown>
      <el-col :lg="6" :span="22">
        <h1 class="font-60 mg-y-less text-center"><span class="color-danger">Ex</span><span class="color-primary">Sim</span></h1>
        <slot></slot>
      </el-col>
  </el-row>
</template>
<script>
import AccountList from '@/components/accounting/AccountList';
import InventoryBalance from '@/components/inventory/InventoryBalance';
import {HTTP} from'@/service/axios'
export default {
	computed: {
		user(){
			return this.$store.state.user
		},
	},
	methods:{
		signout(){
			this.$store.commit('SIGNOUT')
			setTimeout(()=>{
				this.$router.push("/sign-in").catch(err => {})
			},800)	
		}
	}
}
</script>