<template>
	<div>
		<div class="mg-r-1 mg-l-1 mg-t-4 fix-top-left" v-if="user != null">
			<div @click="$router.go(-1)" class="color-lighter cr-pointer">
				<i class="el-icon-back"></i> ย้อนกลับ
			</div>
		</div>
		<DefaultTemplate>
			<el-form @submit.native.prevent="submit()" label-position="top" :model="form" :rules="rules" ref="ruleForm" label-width="120px" >
				<el-form-item :label="(room && room.mode == 'single')?'Create name':'Team name'" prop="groupName">
					<el-input class="rounded" v-model="form.groupName"></el-input>
				</el-form-item>
			</el-form>
			<el-button class="mg-y-5 w-100" type="primary" @click="submit()" :loading="loadingNext" round>OK, go!</el-button>
			<!-- <button class="mg-y-5 w-100 btn-primary" @click="submit()" type="submit" round>Enter</button> -->
		</DefaultTemplate>	
	</div>
	
</template>
<script>
import { HTTP } from'@/service/axios'
import { alertFailError } from'@/service/alertMsg'
import DefaultTemplate from '@/template/DefaultTemplate'
export default {
	components:{
		DefaultTemplate
	},
	computed:{
		room(){
			return this.$store.state.room
		},
		user(){
			return this.$store.state.user
		}
	},
	data(){
		return{
			loadingNext:false,
			form:{
				groupName:'',
			},
			magErrorValid:'',
			rules: {
				groupName: [
					{ required: true, message: 'Please input team name', trigger: 'blur' },
				],  
        	}
		}
	},
	methods:{
		submit(){
			this.loadingNext = true
			let dataForm = {
				groupName:this.form.groupName,
				userId:this.user != null ? this.user.userId : ''
			}
			this.$refs['ruleForm'].validate((valid) => {
          		if (valid) {
          			let filterGroup = this.room.groupId.filter(search => (this.user != null && search == this.user._id))
          			if(filterGroup.length == 0){
	          			HTTP.put('/join/room/'+this.room.roomNumber,dataForm).then(res=>{
	       
	          				if(res.data.success){
								  if(this.user == null){
										this.$store.commit('SET_USER',res.data.obj)
								  }else{
									  Object.keys(res.data.obj).map(item=>{
										let value ={
											label:item,
											value:item == 'userId' ? this.user.userId : res.data.obj[item]
										}
									
										this.$store.commit('SET_DATAUSER',value)
									})
								  }
	          					
								this.$router.push('/waiting').catch(err => {})
								this.$socket.client.emit('getLobby',{roomNumber:this.room.roomNumber})
								this.$socket.client.emit('getRoom',{roomNumber:this.room.roomNumber})
							}else{
								this.magErrorValid = res.data.data
							}
						}).finally(()=>{
							this.loadingNext = false
						}).catch(e=>{
	          				console.log('/join/room/ error',e)
	          				this.alertFailError()
	          			})
					}else{
						this.$router.push('/waiting').catch(err => {})
					}
          		}else{
          			this.loadingNext = false
          		}
			})
		}
	}
}
</script>